import * as React from "react"
import Layout from '../components/layout'
import {StaticImage} from "gatsby-plugin-image";
import '../styles/ticket.scss';

const Ticket = () => {
return(
<Layout>
    <div className="smart-ticket">
        <h2>How Smart Valet Ticket Works?</h2>
        <li className="product-text"> Step 1: Valet give the Vehicle Claim Check to the guest (Ticket bottom part)<br/>
            <div className="ticket"><StaticImage className="smart-img" src='../images/claimcheck.png' alt=""/></div>
        </li>
        <br/>
        <li className="product-text"> Step 2: Guest is about to leave the property and (s)he scans the QR from Vehicle
            Claim Check via Camera (no app installation require) and clicks the Request to Bring Car button.<br/>
            <div className="ticket">
                <StaticImage className="" src='../images/claimcheck.png' alt=""/>
                <StaticImage className="" src='../images/claimcheck3.png' alt=""/>
            </div>
        </li>
        <br/>
        <li className="product-text"> Step 3: Valet staff will get a request alert via Text, App push notification, eMail
            <StaticImage className="" src='../images/claimcheck2.png' alt=""/><br/></li>
    </div>

    <div className="smart-ticket">
        <h2>How To Activate Your Smart Ticket?</h2>
        <li className="product-text"> Step 1: Scan the QR code</li>
        <div className="ticket">
            <StaticImage src='../images/activatedqr.png' alt=""/>
        </div>
        <br/>
        <li className="product-text"> Step 2: Enter the cell number where you would like to receive the Request to Bring Car
            cell text alert.
            <br/></li>
        <br/>
        <div className="ticket">+1  XXX  XXX  XXXX</div>
        <br/><br/>
        <li className="product-text"> Step 3: Verify the cell number with the 6-digit OTP (one-Time-Password)
            <br/></li>
        <br/>
        <div className="ticket">* * * * * * </div>
    </div>

    <div className="smart-ticket">
        <h2>How To Access Other Value-added Features?</h2>
        <li className="product-text"> To get App Push Notification visit <a href='https://ddzone.xyz'> https://ddzone.xyz</a> and download the DD Digital app</li><br/>
        <li className="product-text"> SignIn with cell number (Step 2: +1  XXX  XXX  XXXX), OTP and default PIN 0000</li><br/>
        <li className="product-text"> To get Email notification open DD Digital app >> goto Settings >> Email Setup >>
            add emails</li><br/>
        <li className="product-text"> For demo or support please reach out to us <a href='https://ddzone.xyz/support'>https://ddzone.xyz/support</a> OR Scan below
            code</li>
            <StaticImage src='../images/scanqr.png'alt=""/>
    </div>
</Layout>
)
}

export default  Ticket
